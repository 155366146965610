import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["schoolsModal", "content"];

  show(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;
    const school_count = event.currentTarget.dataset.school_count;
    if (school_count == 0) {
      alert("Aucune école disponible pour ce centre.");
      return;
    }
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        this.contentTarget.innerHTML = html; 
        this.schoolsModalTarget.classList.add("is-active"); 
      });
  }

  destroy() {
    this.schoolsModalTarget.classList.remove("is-active");
    this.contentTarget.innerHTML = "";
  }
}
